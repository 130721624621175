<template>
    <div>
      <template v-for="(item, index) in items">
        <v-chip small class="mr-1" v-if="index < show_length || show_all_items" :key="`country-${index}`">{{ item }}</v-chip>
      </template>
        <v-btn
          small
          outlined
          rounded
          v-if="!show_all_items && (items.length - 5) > 0"
          @click="show_all_items = true"
          >
          Show {{ items.length - 5 }} more
        </v-btn>
    </div>
</template>

<script>
export default {
  data() {
    return{
      show_all_items: false
    }
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    show_length: {
      type: Number,
      required: false,
      default: 5
    }
  },
  methods: {

  }
}
</script>