<template>
    <v-card
      round
      class="my-4 pb-2"
      outlined
      :disabled="disabled"
      >
      <v-form @submit.prevent="save_method" ref="methods_form">
        <v-row>
          <v-col cols="10">
            <v-card-title v-if="shipping_method">
              {{ shipping_method.title }}
              {{ shipping_method.sf_carrier_account_id ? `: ${company.carriers.filter(c => c.id === shipping_method.sf_carrier_account_id)[0].name}` : ''}}
              </v-card-title>
            <v-card-subtitle v-if="shipping_method">
              <ExpandableChipGroup
                :items="shipping_method.countries" />
            </v-card-subtitle>
          </v-col>
          <v-col cols="2" class="d-flex justify-center align-center" v-if="existing_method">
            <v-btn icon outlined @click="$emit('remove')">
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <template v-if="existing_method">
          <v-divider/>
          <v-card-text>
            <v-row>
              <v-col cols="3">
                <p>{{ shipping_method.carrier_description }}</p>
                <p>{{ shipping_method.service_description }}</p>
              </v-col>
              <v-col cols="3">
                {{ shipping_method.label.description }}
              </v-col>
              <v-col cols="3">
                {{ shipping_method.addons.map(a => a.description).toString() }}
              </v-col>
              <v-col cols="3">
                <p>Min {{ shipping_method.min_weight_in_grams }} g </p>
                <p>Max {{ shipping_method.max_weight_in_grams }} g</p>
              </v-col>

            </v-row>
          </v-card-text>
        </template>
      <template v-if="is_expanded || preset_country">
        <v-card-text>
          <!-- <v-card-subtitle class="text-h6 text-center">
            Add carrier
          </v-card-subtitle> -->
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="carrier"
                :items="company.carriers"
                item-text="name"
                item-value="id"
                label="Carrier account"
                no-data-text="No carrier accounts available. Add a carrier to get started"
                />
            </v-col>
              <template v-if="carrier && !shipping.carriers[carrier]">
                <v-progress-circular
                  indeterminate
                  color="primary"/>
              </template>
              <template v-else>
                  <v-col v-if="carrier">
                <!-- <v-col cols="12"> -->
                  <v-select
                    v-model="carrier_id"
                    :items="shipping.carriers[carrier]"
                    item-text="description"
                    return-object
                    label="Carrier company"
                    />
                </v-col>
                <v-col cols="12" v-if="carrier_id">
                  <v-select
                    v-model="service_id"
                    :items="preset_country ? get_available_methods({ carrier_account_id: carrier, carrier_id: carrier_id.id, country: preset_country}) : carrier_id.services"
                    item-text="uniqueDescription"
                    return-object
                    label="Service"
                    />
                </v-col>
                <v-col cols="12" v-if="service_id && company.carriers.find(i => i.id === carrier).type === 'unifaun'">
                  <v-card outlined>
                    <v-card-title>Payment method</v-card-title>
                    <v-card-text class="d-flex align-center">
                      Credit w/ delivery notice
                    <v-switch
                      v-model="paymentMethodType"
                      true-value="INVO"
                      false-value="INVODN"
                      class="mx-2"
                      />
                      Credit w/o delivery notice
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="addons"
                    multiple
                    chips
                    v-if="service_id"
                    :items="all_addons"
                    item-text="description"
                    return-object
                    label="Add ons"
                    />
                </v-col>
                <v-col 
                  cols="12" 
                  v-for="(addon, ai) in addons.filter(addon => addon.values)"
                  :key="ai"
                  >
                  <v-row outlined 
                    v-for="(key, vk) in addon.values"
                    :key="vk"
                    >
                    <v-col cols="12">
                      <v-combobox
                        small-chips
                        outlined
                        round
                        v-model="key.expects"
                        :items="config.addon_expected_values"
                        :label="`Addon ${addon.description}: ${key.description} expects`"
                        hint="Choose a variable in the list or type a custom value"
                        />
                    </v-col>
                  </v-row>
                  
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="label_type"
                    v-if="service_id"
                    :items="get_carrier_print_format(carrier)"
                    :rules="[v => !!v || 'Required']"
                    return-object
                    item-text="description"
                    label="Label type"
                    >
                    <template v-slot:item="{ item }">
                      <div class="d-flex flex-column">
                        {{ item.description }}
                        <div v-if="get_used_label_formats.some(a => a === item.title)">
                          <div class="text-caption">Previously used</div>
                        </div>
                      </div>
                      </template>
                    </v-select>
                </v-col>
                <template v-if="!preset_country">
                  <v-col cols="6">
                    <v-text-field
                      round
                      outlined
                      type="number"
                      :rules="[v => !!v || v === 0 || 'Required']"
                      v-model="min_weight"
                      label="Min weight per parcel (grams)"
                      hint="If the products in a parcel weighs less than this, the parcel weight will be set to this"
                      />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      round
                      outlined
                      type="number"
                      :rules="[v => !!v || 'Required']"
                      v-model="max_weight"
                      label="Max weight per parcel (grams)"
                      hint="If the products in an order weights more than this, the order will be split up on multiple parcels."
                      />
                  </v-col>
                </template>
                <v-col cols="12" v-if="!preset_country">
                  <v-autocomplete
                    v-model="countries"
                    v-if="service_id"
                    multiple
                    :items="route_countries"
                    label="To countries"
                    >
                      <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index < 5">
                        <span>{{ item }}</span>
                      </v-chip>
                      <span
                        v-if="index === 5"
                        class="grey--text text-caption"
                      >
                        (+{{ countries.length - 5 }} others)
                      </span>
                    </template>
                    <template v-slot:append-outer>
                      <v-btn
                        small
                        outlined
                        @click="countries.length ? countries = [] : countries = route_countries"
                        >
                        {{ countries.length ? 'Clear all' : 'Select all'}}
                      </v-btn>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col 
                  cols="12"
                  class="text-center"
                  v-if="service_id && (countries.length || preset_country)"
                  >
                  <v-btn
                    color="primary"
                    type="submit"
                    >
                    {{ preset_country ? 'Add to parcel' : 'Save'}}
                  </v-btn>
                </v-col>

              </template>

            <!-- </v-col> -->
          </v-row>
        </v-card-text>
      </template>
      <div class="text-center" v-if="!preset_country">
        <v-btn  
          icon
          color="primary"
          @click="is_expanded = !is_expanded"
          >
          <v-icon>{{ is_expanded ? 'mdi-arrow-up' : 'mdi-arrow-down'}}</v-icon>
          </v-btn>
      </div>
      </v-form>
    </v-card>
</template>

<script>

import { mapState, mapGetters } from 'vuex'
import ExpandableChipGroup from '@/components/ExpandableChipGroup'

export default {
  components: {
    ExpandableChipGroup
  },
  props: {
    shipping_method: {
      type: Object,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    store: {
      type: Object,
      required: false,
      default: null
    },
    preset_country: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    ...mapState({
      company: state => state.warehouse.company,
      shipping: state => state.shipping,
      config: state => state.app.config,
    }),
    ...mapGetters({
      get_available_methods: 'shipping/get_available_methods',
      get_carrier_print_format: 'app/get_carrier_print_format',
      get_used_label_formats: 'warehouse/get_used_label_formats'
    }),
    existing_method(){
      return this.shipping_method?.service_id
    },
    test(){
      return this.carrier_id.services.reduce((tot, service) => (
        tot.concat(service.addons.filter(addon => !tot.some(s => s.id === addon.id)))
      ), [])
    },
    all_addons(){
      return this.service_id.addons.concat(this.config.addons[this.service_id.id] || [])
    },
    route_countries(){
      if(this.preset_country) return []
      let countries = this.shipping_method.countries
      // const has_wildcard = (countries.indexOf('*') !== -1)
      if(!this.carrier_id || !this.service_id) return countries
      const serviced_countries = this.service_id.routes
        .reduce((tot, route) => tot.concat(route.to), [])
      // if(has_wildcard) countries = all_countries.map(a => a.code) // serviced_countries
      countries = countries.filter(country => serviced_countries.indexOf(country) !== -1)
      for(const addon of this.addons){
        if(!addon.routes) continue
        const allowed = addon.routes.reduce((tot, route) => tot.concat(route.to), [])
        countries = countries.filter(country => allowed.indexOf(country) !== -1)
      }
      if(!this.existing_method){
        const mapped_countries = this.store.shipping_map
          .filter(a => a.title === this.shipping_method.title)
          .reduce((tot, method) => tot.concat(method.countries), [])
        countries = countries.filter(country => mapped_countries.indexOf(country) === -1)
      }
      countries.sort()
      return countries
    }
  },
  watch: {
    carrier: async function(id){
      if(id && !this.shipping.carriers[id]) await this.$store.dispatch('shipping/get_carrier_partners', id)
        if(this.existing_method){
          this.carrier_id = this.shipping.carriers[this.shipping_method.sf_carrier_account_id].find(c => c.id === this.shipping_method.carrier_id)
          this.service_id = this.carrier_id.services.find(s => s.id === this.shipping_method.service_id)
          this.addons = this.service_id.addons
            .filter(a => this.shipping_method.addons
              .some(s => s.id === a.id)
            ).map(addon => ({ ...addon, values: addon.values.map(value => ({
              ...value,
              expects: this.shipping_method.addons.find(a => a.id === addon.id).values.find(v => v.id === value.id).expects
            }))}))
          // this.countries = this.shipping_method.countries
        }
    },
    route_countries: function(countries){
      this.countries = countries
    },
    is_expanded: function(expanded){
      if(expanded && this.existing_method){
        const sm = this.shipping_method
        this.carrier = sm.sf_carrier_account_id
        this.paymentMethodType = sm.paymentMethodType
        // this.addons = 
        // this.countries = []
        this.min_weight = sm.min_weight_in_grams
        this.max_weight = sm.max_weight_in_grams
        this.label_type = { 
          title: sm.label.type,
          description: sm.label.description,
        }
      }
    }
  },
  data() {
    return {
      is_expanded: false,
      carrier: '',
      carrier_id: null,
      service_id: null,
      paymentMethodType: 'INVO',
      addons: [],
      countries: [],
      min_weight: 0,
      max_weight: 2000,
      show_all_countries: false,
      label_type: null,
    }
  },
  methods: {
    save_method(){
      if(!this.$refs.methods_form.validate()) return
      const req = {
        title: this.shipping_method?.title || null,
        sf_carrier_account_id: this.carrier,
        carrier_id: this.carrier_id.id,
        carrier_description: this.carrier_id.description,
        carrier_name: this.carrier_id.carrier_name,
        service_id: this.service_id.id,
        service_description: this.service_id.uniqueDescription,
        addons: this.addons.map(a => {
          delete a.routes
          return a
        }),
        countries: this.countries,
        paymentMethodType: this.paymentMethodType,
        min_weight_in_grams: Number(this.min_weight),
        max_weight_in_grams: Number(this.max_weight),
        label: {
          type: this.label_type.title,
          description: this.label_type.description,
        },
      }
      this.$emit('save', req)

      this.is_expanded = false
      this.carrier_id = null
      this.carrier = ''
      this.service_id = null
      this.addons = []
      this.countries = []

    }
  },
  async mounted(){
    // console.log(this.get_carrier_print_format('unifaun'));
  }
}
</script>