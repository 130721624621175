<template>
    <v-card
    outlined
    class="pa-2"
    :disabled="!get_plan || !get_plan.allow_auto_carrier"
    >
      <div
        class="d-flex"
        style="gap: 2em"
        >
        <div class="grow">
          <div class="text-h6">
            {{  service.display_name || service.name }}
            <v-chip
              color="primary"
              v-if="is_used"
              small
              >Active</v-chip>
          </div>
          <div class="text-subtitle-2">
            {{  service.description }}
          </div>
        </div>
        <div class="shrink">
            <v-btn
            small
              outlined
              @click="expanded = !expanded"
              >
              {{ expanded ? `Close` : is_used ? 'Edit' : `Add to shop`}}
            <v-icon>
              {{ expanded ? `mdi-chevron-up` : `mdi-chevron-down`}}
            </v-icon>
            </v-btn>
        </div>
      </div>
      <div class="text-caption">
            From {{ service.from_country }} to 
            {{ 
              service.to_countries.length ?  
              `${service.to_countries.join(', ')}` 
              : service.restricted_countries.length ? 
              `all countries except ${service.restricted_countries.join(', ')}`
              : 'all countries'
            }}
      </div>
      <div
        v-if="expanded">
        <v-divider class="my-2" />
        <template v-if="!shipping.carriers[carrier_service.sf_carrier_id]">
          <v-progress-circular
              indeterminate
              color="primary"/>
          </template>
          <template v-else>
            <v-form
              ref="service_form"
              @submit.prevent="save_method"
              >
              <v-row>
                <v-col cols="6"> 
                  <v-select
                    v-model="new_service.label.type"
                    :items="get_carrier_print_format(carrier_service.sf_carrier_id)"
                    :rules="[v => !!v || 'Required']"
                    
                    item-value="title"
                    item-text="description"
                    label="Label type"
                    >
                    <template v-slot:item="{ item }">
                      <div class="d-flex flex-column">
                        {{ item.description }}
                        <div v-if="get_used_label_formats.some(a => a === item.title)">
                          <div class="text-caption">Previously used</div>
                        </div>
                      </div>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      type="number"
                      :rules="[v => !!v || 'Required']"
                      label="Packaging standard weight (grams)"
                      v-model="new_service.standard_packaging_weight_in_grams"
                      />
                  </v-col>
                  <v-col cols="6">
                  <v-text-field
                    label="Name"
                    :rules="[v => !!v || 'Required']"
                    v-model="new_service.customer_facing_name"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Description"
                      v-model="new_service.customer_facing_description"
                      />
                  </v-col>
                  <v-col cols="12" v-if="addons.length">
                    <v-select
                      v-model="new_service.addons"
                      multiple
                      chips
                      :items="addons"
                      :item-text="v => (`${v.description}${v.base_price_in_cents ? ` ( + ${v.currency} ${(v.base_price_in_cents / 100)} / parcel)`: ''}`)"
                      return-object
                      label="Add ons"
                      />
                  </v-col>
                  <template v-if="advanced_expanded">
                    <v-col cols="12">
                      <div class="text-subtitle-1">
                        Product tags
                      </div>
                      <v-alert
                        type="info"
                        dense
                        outlined
                        class="small"
                        v-for="(tag, key) in service.addon_prices_by_line_items"
                        :key="key"
                        >
                        If one or more products in a shipment is tagged with <code>{{ tag.product_tag }}</code>, {{ tag.currency }} {{ tag.base_price_in_cents / 100 }} will be added to the rate for {{  tag.name }}. <ToolTip>{{ tag.description }}</ToolTip>
                      </v-alert>
                    </v-col>
                    <v-col cols="12">
                      <div class="text-subtitle-1">
                        Shipping Groups
                      </div>
                      <div class="text-caption mb-3">
                        You can tag a product with <code>shipping_group_{1-5}</code>, for example <code>shipping_group_1</code>.
                        If an order contains items with this tag, those items will be shipped in a separate parcel. Only methods explicitly made available for each group will be offered.                      
                      </div>
                      <div class="text-caption mb-3">
                        You may also tag a product with <code>optional_group_{1-5}</code>, for example <code>optional_group_1</code>.
                        For example, a poster may be tagged <code>shipping_group_1</code>, and a sticker may then be tagged <code>optional_group_1</code>. If someone orders just a poster and sticker both these will be shipped together. If someone orders a poster, sticker and t-shirt the sticker will be shipped with the t-shirt. If the sticker is not tagged <code>optional_group_1</code> it will be shipped by itself in its own parcel separate from the poster.
                      </div>
                      <v-select
                        multiple
                        chips
                        :rules="[v => !!v.length || 'Required']"
                        :items="shipping_group_options"
                        v-model="new_service.shipping_groups"
                        label="This method will be available for items tagged with"
                        />
                    </v-col>
                    <v-col
                      cols="12"
                      >
                      <v-card
                        outlined
                        class="my-4"
                        >
                        <v-card-title>
                          Lowest denominating name
                          <!-- <ToolTip>
                            If an order is split to multiple shipments using different methods, the lowest denominating name and description will be matched between the shipments quoted and displayed for the customer at checkout if they are matching.
                          </ToolTip> -->
                        </v-card-title>
                        <v-card-subtitle>
                          The lowest denominating name and description is displayed for a customer when an order contains items in different shipping groups,
                           and two or more different shipping services are being used in combination. If the rates have the same lowest denominating name, but different service names, 
                           the lowest denominating name will be presented to the customer at check out. Make sure keep these simple and exactly the same across multiple methods, such as 
                           "Tracked letter", "Untracked letter" etc.
                        </v-card-subtitle>
                        <v-card-text class="d-flex" style="gap: 1em">
                          <v-text-field
                            v-model="new_service.lowest_denominating_name"
                            outlined
                            label="Lowest denominating name"
                            placeholder="Tracked letter"
                            />
                          <v-text-field
                            v-model="new_service.lowest_denominating_description"
                            outlined
                            label="Lowest denominating description"
                            placeholder="Fastest option, tracking number provided"
                            />
                        </v-card-text>
                      </v-card>

                    </v-col>
                  </template>
                  <v-col cols="12" class="d-flex flex-column justify-center" style="gap: 10px">
                    <v-btn
                      x-small
                      text
                      @click="advanced_expanded = !advanced_expanded"
                      >
                      {{ advanced_expanded ? 'Close' : 'Advanced'}}
                      <v-icon>
                        {{ advanced_expanded ? `mdi-chevron-up` : `mdi-chevron-down`}}
                      </v-icon>
                    </v-btn>
                    <v-btn
                      small
                      :loading="loading"
                      color="primary"
                      type="submit"
                      >
                      {{ is_used ? 'Save' : 'Add to shop'}}
                    </v-btn>
                    <v-btn
                      :loading="loading"
                      small
                      text
                      v-if="is_used"
                      @click="$emit('deactivate')"
                      >Deactivate</v-btn>
                  </v-col>
              </v-row>
            </v-form>
          </template>
      </div>

    </v-card>
</template>

<script>
import ToolTip from '@/components/ToolTip.vue'

import { mapState, mapGetters } from 'vuex'

export default {
  components: {
    ToolTip
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    store: {
      type: Object,
      required: true,
      default: null
    },
    carrier_service: {
      type: Object,
      required: true,
      default: null
    },
    service: {
      type: Object,
      required: false,
      // default: () => ({
      //   description: '',
      //   customer_facing_nickname: '',
      //   default_package_weight_in_grams: 0,
      //   to_countries: [],
      //   addons: [],
      //   rules: [],
      // })
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      get_carrier_print_format: 'app/get_carrier_print_format',
      get_used_label_formats: 'warehouse/get_used_label_formats',
      get_plan: 'warehouse/get_plan',
    }),
    ...mapState({
      company: s => s.warehouse.company,
      // service_charges: function(s) { return s.shipping.service_charges[this.carrier_service.type] || []},
      shipping: state => state.shipping,
   }),
   active_shipping_groups: function(){ return this.new_service.shipping_groups.filter(s => s !== 'No specific shipping group')} ,
   is_used: function () {
    return this.carrier_service.active_services.some(acs => acs.sf_id === this.service.id)
   },
   partner_service: function(){
    const sf_id = this.service.id
    // console.log(this.shipping.carriers[this.carrier_service.sf_carrier_id] )
    return (this.shipping.carriers[this.carrier_service.sf_carrier_id][0].services )
      .find(s => s.id === sf_id)
   },
   addons: function(){
    // return this.partner_service?.addons
    //   .filter(({ id }) => this.service.allowed_addons.includes(id))
    //   .map(({ id, description }) => ({ id, description })) || []
    return this.service.allowed_addons
   }
  },
  watch: {
   expanded: async function(val){
    if(!val) return
    if(!this.shipping.carriers[this.carrier_service.sf_carrier_id]) await this.$store.dispatch('shipping/get_carrier_partners', this.carrier_service.sf_carrier_id)
    // if(!this.service_charges[this.carrier_service.type]?.length) await this.$store.dispatch('shipping/get_service_charges', this.carrier_service.type)
   }
  },
  data() {
    return {
      expanded: false,
      advanced_expanded: false,
      shipping_groups_disabled: true,
      new_service: {
        standard_packaging_weight_in_grams: 50,
        addons: [],
        shipping_groups: ['No specific shipping group'],
        customer_facing_name: '',
        customer_facing_description: '',
        lowest_denominating_name: '',
        lowest_denominating_description: '',
        label: {
          type: ''
        }
      },
      // store_shipping_groups: [
      //   {
      //     tag: 'shipping_group_1',
      //     lowest_denominating_name: '',
      //     lowest_denominating_description: '',
      //   },
      //   {
      //     tag: 'shipping_group_2',
      //     lowest_denominating_name: '',
      //     lowest_denominating_description: '',
      //   },
      //   {
      //     tag: 'shipping_group_3',
      //     lowest_denominating_name: '',
      //     lowest_denominating_description: '',
      //   },
      //   {
      //     tag: 'shipping_group_4',
      //     lowest_denominating_name: '',
      //     lowest_denominating_description: '',
      //   },
      //   {
      //     tag: 'shipping_group_5',
      //     lowest_denominating_name: '',
      //     lowest_denominating_description: '',
      //   },
      // ],
      label_type: null,
      shipping_group_options: [
        'No specific shipping group',
        'shipping_group_1',
        'shipping_group_2',
        'shipping_group_3',
        'shipping_group_4',
        'shipping_group_5'
      ]
    }
  },
  methods: {
  // save_shipping_groups(){
  //   this.shipping_groups_disabled = true
  //   this.$store.dispatch('warehouse/save_data_source', { id: this.store.id, shipping_groups: this.store_shipping_groups })
  // },
   async save_method(){
    // TODO Add lowest and highest price
    if(!this.$refs.service_form.validate()) return
    const method = {
      ...this.new_service,
      // label: { 
      //   type: this.label_type.title
      // },
      // shipping_groups: this.new_service.shipping_groups.filter(s => s !== 'No specific shipping group'),
      sf_id: this.service.id,
      service_id: this.service.service_id,
      carrier_id: this.shipping.carriers[this.carrier_service.sf_carrier_id][0].id,
      max_weight_in_grams: this.service.max_weight_in_grams,
      min_weight_in_grams: this.service.min_weight_in_grams,
      mandatory_addons: this.service.mandatory_addons || [],
      sf_carrier_account_id: this.carrier_service.sf_carrier_id,
    }
    this.$emit('save', method)
    this.expanded = false
   }
  },
  async mounted(){
    // if(this.service.label?.type) this.label_type = {
    //   title:  this.service.label?.type
    // }
    // for ( const shipping_group of (this.store.shipping_groups || [])) {
    //   this.store_shipping_groups[this.store_shipping_groups.findIndex(s => s.tag === shipping_group.tag)] = shipping_group
    // }
    
    this.new_service.customer_facing_description = this.service.description
    this.new_service.customer_facing_name = this.service.name
    this.new_service = {
      ...this.new_service,
      ...(this.carrier_service.active_services.find(acs => acs.sf_id === this.service.id) || {})
    }
  }
}
</script>
<style lang="scss" scoped>

// .service_component {
//   border-radius
// }

</style>