<template>
  <v-card
  max-width="100%"
  class="my-8"
  outlined
  >
    <v-card-title>
      Stores
    </v-card-title>
    
    <store-card 
      v-for="(store, index) in company.data_sources"
      :key="`store-${index}`"
      :store="store"
      :index="index"
    />
    <v-row
      outlined
      round
      class="ma-4 pa-4 text-center"
      >
      <v-col cols="12" v-if="show_add_keys" class="text-left">
        <v-alert
          type="info"
          
          >
            <p>
              To add another store to your account, ask the store owner of the store you want to transfer into this account to open "Settings > Stores", switch on "Allow Store Transfer" and copy their Store Id and Store key to you. Paste the Store If and Key below and you will be able to connect.
            </p>
            <p>
              Note that the store you want to transfer need to be on a paid plan, suggested the "Outsourcing"-plan, and you need to be on "Pro"-plan or above. Also, be informed that the store owner may at any time be able to withdraw their store from you at their discretion.
            </p>
        </v-alert>
      </v-col>
      <v-col class="grow" v-if="show_add_keys">
          <v-text-field
            round
            outlined
            v-model="new_store.id"
            label="Store id"
            placeholder="f7f8d9b088d"
            >
          </v-text-field>
      </v-col>
      <v-col class="grow" v-if="show_add_keys">
          <v-text-field
            round
            outlined
            v-model="new_store.transfer_key"
            label="Store transfer key"
            placeholder="7f8d9b088dbf65d775faad868566f8f1"
            >
          </v-text-field>
      </v-col>
      <v-col>
        <v-btn
          @click="add_store"
          color="primary"
          :loading="loading_add_store"
          >
          <v-icon left>mdi-plus</v-icon>
          Add store
        </v-btn>
      </v-col>
    </v-row> 
      
</v-card>
</template>

<script>

import { mapState, mapGetters } from 'vuex'
// import { mapFields } from 'vuex-map-fields'
// import ToolTip from '@/components/ToolTip.vue'
import StoreCard from './StoreCard.vue'

export default {
  components: {
    // ToolTip,
    StoreCard,
    
  },
  data(){
    return {
      save_disabled: true,
      save_loading: false,
      show_add_keys: false,
      loading_add_store: false,
      new_store: {
        id: '',
        transfer_key: '',
      }
      // original_company: { ...this.$store.state.warehouse.company }
    }
  },
  computed: {
    ...mapState({
      company: state => state.warehouse.company
    }),
    ...mapGetters({
      get_plan: 'warehouse/get_plan',
    })
    // ...mapFields([
    //   'warehouse.company.products_refresh_interval',
    //   'warehouse.company.order_bucket_size',
    //   'warehouse.company.days_valid_inventory_count',
    //   'warehouse.company.check_inventory_threshold',
    //   ]),
  },
  methods: {
    is_owner(ds){
      return ds.parent_company_id === this.company.id
    },
    async add_store(){
      if(this.get_plan.max_connected_stores <= this.company.data_sources.length) {
        return this.$store.commit('app/SET_SNACK_BAR', `Your current plan doesn't allow more stores. Upgrade to add more!`)
      }
      if(!this.show_add_keys) return this.show_add_keys = true
      this.loading_add_store = true
      await this.$store.dispatch('warehouse/add_data_source', this.new_store)
      this.loading_add_store = false
      this.show_add_keys = false
      // TODO: Add validation
    }
  },
  mounted(){
  }
}
</script>