<template>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          small
          class="ml-2"
        >
          mdi-information-outline
        </v-icon>
      </template>
      <span><slot></slot></span>
    </v-tooltip>
</template>

<script>



export default {
  components: {
    
  },
  data(){
    return {
      
    }
  },
  computed: {
    
  },
  methods: {
    
  },
  mounted(){
    
  }
}
</script>