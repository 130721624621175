<template>  
    <v-card
      outlined
      round
      class="ma-4 pa-4"
      >
      <v-row>
        <v-col
          cols="6">
        <v-text-field
            round
            outlined
            v-model="store.name"
            label="Store name"
            :disabled="!is_owner"
            @change="update_data_source({name: store.name})"
            >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-switch
            v-model="store.allow_partial_fulfillment"
            flat
            :disabled="!is_owner"
            class="my-0"
            @change="update_data_source({allow_partial_fulfillment: store.allow_partial_fulfillment})"
          >
          <template v-slot:label>
            Allow order splitting
            <ToolTip>If any item in an order is missing, allowing order splitting will create a partial fulfillment.</ToolTip>
          </template>
          </v-switch>
            <v-switch
              v-model="store.allow_transfer"
              flat
              :disabled="!get_plan || !get_plan.allow_store_transfer"
              class="my-0"
              v-if="is_owner && is_fulfiller"
              @change="update_data_source({allow_transfer: store.allow_transfer})"
            >
            <template v-slot:label>
              Allow store transfer
              <ToolTip>If you wish to fulfill this store under another account, open up your store for transfer to get your keys and use these to add the store on your other account.</ToolTip>
            </template>
            </v-switch>
            
            <!-- <v-btn
              @click="request_order_scope"
              v-if="!store.has_full_order_scope"
              x-small
              >Allow {{ $appName }} to access orders older than 60 days</v-btn> -->
            </v-col>
        <v-col cols="6">
          <v-text-field
            round
            outlined
            v-model="store.eori_number"
            label="EORI / customs number"
            :disabled="!is_owner"
            @change="update_data_source({eori_number: store.eori_number})"
            />
        </v-col>
        <v-col cols="6">
          <v-text-field
            round
            outlined
            v-model="store.currency_code"
            label="Currency code"
            :disabled="!is_owner"
            @change="update_data_source({currency_code: store.currency_code})"
            />
        </v-col>
        <template v-if="store.allow_transfer">
          <v-col cols="12" v-if="is_owner && is_fulfiller">
            <v-alert
              type="warning"
              >
            <p>
            Anyone with your "Store Id" and "Store transfer key" will be able to take control of your fulfillment. Unless you intend on transferring this store to another {{ $appName }} account - leave "Allow Store Transfer" off. 
            </p><p>
            If you connect your store to another account, you as the store owner will always be able to take back your store at your sole discretion.
            </p>
            </v-alert>
          </v-col>
          <v-col cols="6" v-if="is_owner && is_fulfiller">
            <v-text-field
              round
              outlined
              type="text"
              v-model="store.id"
              append-outer-icon="mdi-content-copy"
              @click:append-outer="copy(store.id)"
              label="Store id"
              readonly
              >
            </v-text-field>
          </v-col>
          <v-col cols="6" v-if="is_owner && is_fulfiller">
            <v-text-field
              round
              outlined
              :type="!show_key ? 'password' : 'text'"
              v-model="store.transfer_key"
              append-outer-icon="mdi-content-copy"
              append-icon="mdi-eye"
              @click:append="show_key = !show_key"
              @click:append-outer="copy(store.transfer_key)"
              label="Store transfer key"
              readonly
              >
            </v-text-field>
          </v-col>
        </template>
        <v-col cols="12" v-if="!store.subscription_plan">
          <v-alert
            type="error"
            >
            This store owner has uninstalled {{ $appName }}. This store is no longer accessible.
          </v-alert>
        </v-col>
        <v-col v-if="is_owner && !is_fulfiller">
          <!-- <v-alert
            v-if="store.subscription_plan && !get_plan_by_title(store.subscription_plan.title).allow_transfer"
            type="error"
            >
            Your store is transferred but your current Shopify plan doesn't allow store transfer. Upgrade plan to be able to keep your store fulfilled.
          </v-alert> -->
          <v-alert
            type="info"
            >
            <v-row align="center">
              <v-col class="grow">
                Your store is being fulfilled by {{ store.fulfilling_company_name }}
              </v-col>
              <v-col class="shrink">
                <v-btn
                  @click="revoke_transfer"
                  >Revoke</v-btn>
              </v-col>
            </v-row>
            </v-alert>
        </v-col>
        <v-col cols="12" v-if="!is_owner && is_fulfiller">
          <v-alert
            type="info"
            >
            <v-row align="center">
              <v-col class="grow">
                This is a store shared with you, owned by someone else
              </v-col>
              <v-col class="shrink">
                <v-btn
                  @click="remove_store"
                  >Remove store</v-btn>
              </v-col>
            </v-row>
            </v-alert>
        </v-col>
        <v-col
          cols="12"
          class="text-center"
          >
          <v-dialog
            v-model="dialog"
            fullscreen
            >
          <template v-slot:activator="{ on, attrs }">
          <v-btn
            outlined
            color="primary"
            class="text-caption"
            v-bind="attrs"
            v-on="on"
            >
            <v-icon left>mdi-truck-delivery-outline</v-icon>
            Shipping settings
          </v-btn>
          </template>
          <ShippingMapDialog 
            @close="dialog = false"
            :store="store"
            />
          </v-dialog>
        </v-col>
      </v-row>

      </v-card>
    
</template>

<script>

import { mapState, mapGetters } from 'vuex'
// import { mapFields } from 'vuex-map-fields'
import ToolTip from '@/components/ToolTip.vue'
import ShippingMapDialog from '@/components/ShippingMapDialog'
// import { uid } from 'uid'

export default {
  props: {
    store: {
      required: true,
      type: Object,
    },
    index: {
      required: true,
      type: Number,
    }
  },
  components: {
    ToolTip,
    ShippingMapDialog,
    
  },
  data(){
    return {
      show_key: false,
      dialog: false,
      // original_company: { ...this.$store.state.warehouse.company }
    }
  },
  computed: {
    ...mapState({
      company: state => state.warehouse.company
    }),
    ...mapGetters({
      get_plan: 'warehouse/get_plan',
      // get_plan_by_title: 'app/get_plan_by_title',
    }),
    is_owner(){
      return this.store.parent_company_id === this.company.id
    },
    is_fulfiller(){
      return this.store.fulfilling_company_id === this.company.id
    }
    // ...mapFields([
    //   'warehouse.company.products_refresh_interval',
    //   'warehouse.company.order_bucket_size',
    //   'warehouse.company.days_valid_inventory_count',
    //   'warehouse.company.check_inventory_threshold',
    //   ]),
  },
  methods: {
    // request_order_scope(){
    //   const url = `https://${this.store.myshopify_name}/admin/oauth/authorize?client_id=${process.env.VUE_APP_SHOPIFY_CLIENT_ID}&scope=${'read_all_orders,write_orders,write_inventory,read_products,read_locations,read_shipping'}&redirect_uri=${window.location.origin}/&state=${uid()}`
    //   return window.open(url, '_top')
    // },
    copy(text){
      navigator.clipboard.writeText(text)
    },
    update_data_source(set){
      this.$store.dispatch('warehouse/save_data_source', {
        id: this.store.id,
        ...set
      })
    },
    remove_store(){
      this.$store.dispatch('warehouse/save_data_source', {
        id: this.store.id,
        fulfilling_company_id: this.store.parent_company_id,
        fulfilling_company_name: '',
      })
    },
    revoke_transfer(){
      this.$store.dispatch('warehouse/save_data_source', {
        id: this.store.id,
        fulfilling_company_id: this.company.id,
        fulfilling_company_name: this.company.name,
      })
    }
  },
  mounted(){
    if(this.$route.query.showDialog) this.dialog = true
    else this.dialog = false
  }
}
</script>